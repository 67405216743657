import { STATUS, ORIGEM_MSG, STATUSMESSAGE } from "../../../pages/whatsapp/constants";
import {
  WHATSAPP__SET_STATUS,
  WHATSAPP__SET_ID_CHAT_ABERTO,
  WHATSAPP__SET_CONFIGURACAO,
  WHATSAPP__SET_LOADING,
  WHATSAPP__SET_CONTATOS_LISTA,
  WHATSAPP__ADD_CONTATO_LISTA,
  WHATSAPP__SET_MENSAGEM_LIDA,
  WHATSAPP__ADD_MENSAGEM,
  WHATSAPP__SET_MENSAGENS,
  WHATSAPP__SET_MENSAGEM,
  WHATSAPP__SET_SHARED_PARAMS,
  WHATSAPP__OPEN_MODAL_HISTORY,
  WHATSAPP__CLOSE_MODAL_HISTORY,
  WHATSAPP__MODAL_HISTORY_ADD_MESSAGE,
  WHATSAPP__MODAL_HISTORY_SET_MESSAGE,
  WHATSAPP__OPEN_MODAL_TRANSFERCHAT,
  WHATSAPP__CLOSE_MODAL_TRANSFERCHAT,
  WHATSAPP__SET_FORCE_UPDATE_STATE,
  WHATSAPP__OPEN_MODAL_HSM_SEND,
  WHATSAPP__CLOSE_MODAL_HSM_SEND,
} from "./types";

export const initialState = () => ({
  idChatAberto: null,
  loading: false,
  habilitado: false,
  status: 0,
  ddi: "",
  telefone: "",
  codigoCampanha: null,
  contatos: {},
  mensagens: [],
  sharedParams: {},
  forcarAtualizacao: null,
  modais: {
    historico: {
      permiteEnvioMsg: false,
      somenteLeitura: false,
      contato: {},
      aberto: false,
      titulo: "",
      mensagens: [],
    },
    transferenciaChat: {
      aberto: false,
      chat: {},
    },
    envioHSM: {
      aberto: false,
      destinatario: null,
      templates: [],
      destinatarios: [],
    },
  },
});

const whatsApp = (state = initialState(), action) => {
  switch (action.type) {
    case WHATSAPP__SET_CONFIGURACAO:
      return {
        ...state,
        ...action.payload,
        idChatAberto: null,
        loading: false,
        contatos: {},
        mensagens: [],
      };

    case WHATSAPP__SET_ID_CHAT_ABERTO:
      return {
        ...state,
        idChatAberto: action.payload,
      };

    case WHATSAPP__SET_STATUS: {
      const status = action.payload;
      if (status === STATUS.DISCONNECTED) {
        return {
          ...state,
          status: action.payload,
          idChatAberto: null,
          contatos: {},
          mensagens: [],
        };
      }
      return {
        ...state,
        status: action.payload,
      };
    }

    case WHATSAPP__SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case WHATSAPP__ADD_CONTATO_LISTA: {
      const novoContato = action.payload;
      const { contatos } = state;

      contatos[novoContato.telefoneLead] = novoContato;

      return {
        ...state,
        contatos,
      };
    }

    case WHATSAPP__SET_CONTATOS_LISTA: {
      const contatos = {};

      action.payload.forEach(contato => {
        contatos[contato.telefoneLead] = contato;
      });

      return {
        ...state,
        contatos,
      };
    }

    case WHATSAPP__SET_MENSAGEM_LIDA: {
      const idChat = action.payload;
      const { contatos, mensagens } = state;
      let newState = { ...state };

      if (contatos[idChat]) {
        contatos[idChat].qtdNaoLidos = 0;
        newState = {
          ...state,
          contatos,
          mensagens: mensagens.map(mensagem => {
            if (mensagem.telefoneLead === idChat && mensagem.origem === ORIGEM_MSG.LEAD) {
              return { ...mensagem, statusMessage: STATUSMESSAGE.READ };
            }
            return mensagem;
          }),
        };
      } else if (idChat && state.modais.historico.contato?.telefoneLead === idChat) {
        return {
          ...state,
          modais: {
            ...state.modais,
            historico: {
              ...state.modais.historico,
              mensagens: state.modais.historico.mensagens.map(mensagem => {
                if (mensagem.telefoneLead === idChat && mensagem.origem === ORIGEM_MSG.LEAD) {
                  return { ...mensagem, statusMessage: STATUSMESSAGE.READ };
                }
                return mensagem;
              }),
            },
          },
        };
      }

      if (idChat && newState.modais.historico.contato?.telefoneLead === idChat) {
        newState = {
          ...newState,
          modais: {
            ...state.modais,
            historico: {
              ...state.modais.historico,
              mensagens: state.modais.historico.mensagens.map(mensagem => {
                if (mensagem.telefoneLead === idChat && mensagem.origem === ORIGEM_MSG.LEAD) {
                  return { ...mensagem, statusMessage: STATUSMESSAGE.READ };
                }
                return mensagem;
              }),
            },
          },
        };
      }
      return newState;
    }

    case WHATSAPP__ADD_MENSAGEM: {
      const { mensagens, contatos, idChatAberto } = state;
      const { idChat, message } = action.payload;

      if (idChat !== idChatAberto && message.origem === ORIGEM_MSG.LEAD) {
        contatos[idChat].qtdNaoLidos += 1;
      }
      if (message.origem === ORIGEM_MSG.LEAD) {
        contatos[idChat].dateTimezoneUltimaMsgRecebida = message.dateTimezone;
      }

      contatos[idChat].ciclo = message.ciclo;
      contatos[idChat].snippet = message.mensagem;
      contatos[idChat].tipo = message.tipo;
      contatos[idChat].data = message.data;
      contatos[idChat].dateTimezone = message.dateTimezone;
      contatos[idChat].origem = message.origem;
      contatos[idChat].usuarioId = message.usuarioId;

      return {
        ...state,
        mensagens: idChat === idChatAberto ? [...mensagens, message] : mensagens,
        contatos: { ...contatos },
        forcarAtualizacao: new Date(),
      };
    }

    case WHATSAPP__SET_MENSAGEM: {
      const { mensagens, contatos } = state;
      const { idChat, message } = action.payload;

      if (contatos[idChat]) {
        contatos[idChat].tipo = message.tipo;
        contatos[idChat].snippet = message.mensagem;
        contatos[idChat].mensagem = message.mensagem;
      }

      return {
        ...state,
        mensagens: mensagens.map(msg => {
          if (msg.id === message.id) return { ...message, dateTimezone: msg.dateTimezone };
          return msg;
        }),
        contatos: { ...contatos },
        forcarAtualizacao: new Date(),
      };
    }

    case WHATSAPP__SET_MENSAGENS:
      return {
        ...state,
        mensagens: [...action.payload],
      };

    case WHATSAPP__SET_SHARED_PARAMS:
      return {
        ...state,
        sharedParams: action.payload,
      };

    case WHATSAPP__OPEN_MODAL_HISTORY: {
      const { title, messages, permiteEnvioMsg, somenteLeitura, contato } = action.payload;

      return {
        ...state,
        modais: {
          ...state.modais,
          historico: {
            permiteEnvioMsg,
            somenteLeitura,
            contato,
            aberto: true,
            titulo: title,
            mensagens: messages,
          },
        },
      };
    }

    case WHATSAPP__CLOSE_MODAL_HISTORY:
      return {
        ...state,
        modais: {
          ...state.modais,
          historico: {
            permiteEnvioMsg: false,
            somenteLeitura: false,
            contato: {},
            aberto: false,
            titulo: "",
            mensagens: [],
          },
        },
      };

    case WHATSAPP__MODAL_HISTORY_ADD_MESSAGE: {
      const { message, permiteEnvioMsg } = action.payload;
      return {
        ...state,
        modais: {
          ...state.modais,
          historico: {
            ...state.modais.historico,
            permiteEnvioMsg,
            mensagens: [...state.modais.historico.mensagens, message],
          },
        },
      };
    }

    case WHATSAPP__MODAL_HISTORY_SET_MESSAGE: {
      const { message } = action.payload;
      return {
        ...state,
        modais: {
          ...state.modais,
          historico: {
            ...state.modais.historico,
            mensagens: state.modais.historico.mensagens.map(msg => {
              if (msg.id === message.id) return { ...message, dateTimezone: msg.dateTimezone };
              return msg;
            }),
          },
        },
      };
    }

    case WHATSAPP__OPEN_MODAL_TRANSFERCHAT: {
      const { chat } = action.payload;

      return {
        ...state,
        modais: {
          ...state.modais,
          transferenciaChat: {
            aberto: true,
            chat,
          },
        },
      };
    }

    case WHATSAPP__CLOSE_MODAL_TRANSFERCHAT:
      return {
        ...state,
        modais: {
          ...state.modais,
          transferenciaChat: {
            aberto: false,
            chat: {},
          },
        },
      };

    case WHATSAPP__SET_FORCE_UPDATE_STATE:
      return {
        ...state,
        forcarAtualizacao: new Date(),
      };

    case WHATSAPP__OPEN_MODAL_HSM_SEND: {
      const { destinatarios, destinatario, templates } = action.payload;
      return {
        ...state,
        modais: {
          ...state.modais,
          envioHSM: {
            ...state.modais.envioHSM,
            aberto: true,
            templates,
            destinatarios,
            destinatario,
          },
        },
      };
    }

    case WHATSAPP__CLOSE_MODAL_HSM_SEND:
      return {
        ...state,
        modais: {
          ...state.modais,
          envioHSM: {
            ...state.modais.envioHSM,
            aberto: false,
            templates: [],
            destinatarios: [],
            destinatario: null,
          },
        },
      };

    default:
      return state;
  }
};

export default whatsApp;
