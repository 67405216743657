import {
  VARIAVEIS_AMBIENTE__BUSCAR_LISTA_REQUEST,
  VARIAVEIS_AMBIENTE__BUSCAR_LISTA_RESPONSE,
} from "./variaveisAmbienteActions";

export const returnInitialState = () => ({
  isRequesting: null,
});

const variaveisAmbiente = (state = returnInitialState(), action) => {
  switch (action.type) {
    case VARIAVEIS_AMBIENTE__BUSCAR_LISTA_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case VARIAVEIS_AMBIENTE__BUSCAR_LISTA_RESPONSE:
      return {
        ...state,
        isRequesting: false,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default variaveisAmbiente;
