import { get } from "axios";

import { CachedRequests } from "../../../_common/utils/cachedRequests";
import createAction from "../../../_common/utils/redux/createAction";
import env from "../../../../src/environment";
// import { CachedRequests } from "../../../_common/utils/cachedRequests";

export const VARIAVEIS_AMBIENTE__BUSCAR_LISTA_REQUEST = "VARIAVEIS_AMBIENTE/BUSCAR_LISTA_REQUEST";
export const VARIAVEIS_AMBIENTE__BUSCAR_LISTA_RESPONSE = "VARIAVEIS_AMBIENTE/BUSCAR_LISTA_RESPONSE";
export const VARIAVEIS_AMBIENTE__BUSCAR_LISTA_ERROR = "VARIAVEIS_AMBIENTE/BUSCAR_LISTA_ERRO";

const buscarListaRequest = () => createAction(VARIAVEIS_AMBIENTE__BUSCAR_LISTA_REQUEST);
const buscarListaResponse = listaVariaveis =>
  createAction(VARIAVEIS_AMBIENTE__BUSCAR_LISTA_RESPONSE, { listaVariaveis });
const buscarListaError = err => createAction(VARIAVEIS_AMBIENTE__BUSCAR_LISTA_ERROR, { err });

export const buscarLista = () => async dispatch => {
  dispatch(buscarListaRequest());
  try {
    const baseUrl = env.REACT_APP_BASE_URL;

    const URL = `${baseUrl}/api/pipeline/Appsetting/BuscarLista`;
    const listaVariaveis = await CachedRequests.get(
      URL,
      async () =>
        (await get(URL, {
          headers: {
            "Content-Type": "application/json",
          },
        })).data,
      { maxAge: 20 * 60 }
    );

    window.listaVariaveis = listaVariaveis;
    dispatch(buscarListaResponse(listaVariaveis));
    return listaVariaveis;
  } catch (err) {
    dispatch(buscarListaError(err));
    throw err;
  }
};
