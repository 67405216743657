export const WHATSAPP__SET_STATUS = "WHATSAPP__SET_STATUS";
export const WHATSAPP__SET_ID_CHAT_ABERTO = "WHATSAPP__SET_ID_CHAT_ABERTO";
export const WHATSAPP__SET_CONFIGURACAO = "WHATSAPP__SET_CONFIGURACAO";
export const WHATSAPP__SET_LOADING = "WHATSAPP__SET_LOADING";
export const WHATSAPP__SET_CONTATOS_LISTA = "WHATSAPP__SET_CONTATOS_LISTA";
export const WHATSAPP__ADD_CONTATO_LISTA = "WHATSAPP__ADD_CONTATO_LISTA";
export const WHATSAPP__REM_CONTATO_LISTA = "WHATSAPP__REM_CONTATO_LISTA";
export const WHATSAPP__SET_MENSAGEM_LIDA = "WHATSAPP__SET_MENSAGEM_LIDA";
export const WHATSAPP__ADD_MENSAGEM = "WHATSAPP__ADD_MENSAGEM";
export const WHATSAPP__SET_MENSAGENS = "WHATSAPP__SET_MENSAGENS";
export const WHATSAPP__SET_MENSAGEM = "WHATSAPP__SET_MENSAGEM";
export const WHATSAPP__SET_SHARED_PARAMS = "WHATSAPP__SET_SHARED_PARAMS";
export const WHATSAPP__MODAL_HISTORY_ADD_MESSAGE = "WHATSAPP__MODAL_HISTORY_ADD_MESSAGE";
export const WHATSAPP__MODAL_HISTORY_SET_MESSAGE = "WHATSAPP__MODAL_HISTORY_SET_MESSAGE";
export const WHATSAPP__OPEN_MODAL_HISTORY = "WHATSAPP__OPEN_MODAL_HISTORY";
export const WHATSAPP__CLOSE_MODAL_HISTORY = "WHATSAPP__CLOSE_MODAL_HISTORY";
export const WHATSAPP__OPEN_MODAL_TRANSFERCHAT = "WHATSAPP__OPEN_MODAL_TRANSFERCHAT";
export const WHATSAPP__CLOSE_MODAL_TRANSFERCHAT = "WHATSAPP__CLOSE_MODAL_TRANSFERCHAT";
export const WHATSAPP__SET_FORCE_UPDATE_STATE = "WHATSAPP__SET_FORCE_UPDATE_STATE";
export const WHATSAPP__OPEN_MODAL_HSM_SEND = "WHATSAPP__OPEN_MODAL_HSM_SEND";
export const WHATSAPP__CLOSE_MODAL_HSM_SEND = "WHATSAPP__CLOSE_MODAL_HSM_SEND";
