import moment from "moment";
import momentTz from "moment-timezone";

import createAction from "../../../_common/utils/redux/createAction";
import { pt, en, es } from "../../../locales/moment";

// Timezone padrão baseado nas opções do navegador do usuário
const DEFAULT_TIMEZONE = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const LOCALE__SET_REQUEST = "LOCALE/SET_REQUEST";
export const LOCALE__SET_RESPONSE = "LOCALE/SET_RESPONSE";
export const LOCALE__SET_ERROR = "LOCALE/SET_ERROR";

const setLocaleRequest = language => createAction(LOCALE__SET_REQUEST, { language });
const setLocaleResponse = messages => createAction(LOCALE__SET_RESPONSE, { messages });
const setLocaleError = (language, error) => createAction(LOCALE__SET_ERROR, { language, error });

export const setLocale = language => async dispatch => {
  try {
    dispatch(setLocaleRequest(language));

    const messages = await import(`../../../locales/compiledMessages/${language}.json`);

    dispatch(setLocaleResponse(messages.default));
  } catch (e) {
    dispatch(setLocaleError(language, e));
  }
};

export const configureMoment = (lang, timezone = DEFAULT_TIMEZONE) => {
  momentTz.tz.setDefault(timezone);

  switch (lang.toUpperCase()) {
    case "PT-BR": {
      moment.locale("pt-BR", pt);
      momentTz.locale("pt-BR", pt);
      break;
    }
    case "ES-ES": {
      moment.locale("es-ES", es);
      momentTz.locale("es-ES", es);
      break;
    }
    case "EN-US": {
      moment.locale("en-US", en);
      momentTz.locale("en-US", en);
      break;
    }
    default:
      break;
  }
};
