import {
  USUARIO__BUSCAR_LOGADO_ERRO,
  USUARIO__BUSCAR_LOGADO_SUCESSO,
  USUARIO__BUSCAR_LOGADO_REQUEST,
  USUARIO__ATUALIZAR,
  USUARIO__UPDATE_LOGOTIPO,
  USUARIO__LOGADO,
} from "./usuarioActions";

export const returnInitialState = () => ({
  featureToggles: {},
  isRequesting: null,
});

const user = (state = returnInitialState(), action) => {
  switch (action.type) {
    case USUARIO__BUSCAR_LOGADO_ERRO:
      return {
        erro: action.payload,
      };
    case USUARIO__BUSCAR_LOGADO_SUCESSO: {
      return {
        ...state,
        ...action.payload,
        isRequesting: false,
        isGerente: action.payload.roles.includes("Gerente"),
        isPreVendedor: action.payload.roles.includes("PreVendedor"),
        isVendedor: action.payload.roles.includes("Vendedor"),
        isAdmin: action.payload.roles.includes("Admin"),
        isPVOrVendedor: action.payload.roles.includes("PreVendedor") || action.payload.roles.includes("Vendedor"),
        isPerfilDesativado: action.payload.roles.includes("Desativado"),
        cargo: action.payload.roles.map(elem => (elem === "PreVendedor" ? "Pré-Vendedor" : elem))[0],
      };
    }
    case USUARIO__ATUALIZAR: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case USUARIO__BUSCAR_LOGADO_REQUEST:
      return {
        ...state,
        isRequesting: true,
      };
    case USUARIO__UPDATE_LOGOTIPO: {
      return {
        ...state,
        urlLogoEmpresa: action.payload,
      };
    }
    case USUARIO__LOGADO: {
      return {
        ...state,
        isUsuarioLogado: action.payload,
      };
    }
    default:
      return state;
  }
};

export default user;
