import { compose, createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import env from "../src/../environment";

import rootReducer from "./reducers";

const middlewares = [applyMiddleware(thunk)];

// Redux Devtools
if (env.NODE_ENV === "development" && window.__REDUX_DEVTOOLS_EXTENSION__) {
  middlewares.push(window.__REDUX_DEVTOOLS_EXTENSION__());
}

const enhancer = compose(...middlewares);

export default createStore(rootReducer, undefined, enhancer);
