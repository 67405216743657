// eslint-disable-next-line no-unused-vars
import Axios from "axios";
import { v4 } from "uuid";

import createAction from "../../../_common/utils/redux/createAction";
import { objectKeysToCamelCase } from "../../../_common/utils/string";
import { setConfiguracao } from "../whatsapp/actions";
import { CachedRequests } from "../../../_common/utils/cachedRequests";
import { INFOS_USUARIO } from "../../../_common/utils/storage/constantesStorage";
import localStorageManager from "../../../_common/utils/storage";
import {
  requestInterceptor,
  requestInterceptorError,
  responseInterceptor,
  errorResponseInterceptor,
} from "../../../_common/utils/api/interceptors";
import env from "../../src/../../environment";

export const USUARIO__LOGADO = "USUARIO__LOGADO";
export const USUARIO__BUSCAR_LOGADO_REQUEST = "USUARIO/BUSCAR_LOGADO_REQUEST";
export const USUARIO__BUSCAR_LOGADO_SUCESSO = "USUARIO/BUSCAR_LOGADO_SUCESSO";
export const USUARIO__BUSCAR_LOGADO_ERRO = "USUARIO/BUSCAR_LOGADO_ERRO";
export const USUARIO__REDUZIR_CREDITOS = "USUARIO/REDUZIR_CREDITOS";
export const USUARIO__ATUALIZAR = "USUARIO/USUARIO__ATUALIZAR";
export const USUARIO__UPDATE_LOGOTIPO = "USUARIO/UPDATE_LOGOTIPO";

export const usuarioEstaLogado = isLogado => createAction(USUARIO__LOGADO, isLogado);

export const buscarLogadoRequest = () => createAction(USUARIO__BUSCAR_LOGADO_REQUEST);

export const buscarLogadoSucesso = usuario => createAction(USUARIO__BUSCAR_LOGADO_SUCESSO, usuario);

export const buscarLogadoErro = erro => createAction(USUARIO__BUSCAR_LOGADO_ERRO, erro);

export const atualizar = usuario => createAction(USUARIO__ATUALIZAR, usuario);

const URL = "/Usuarios/BuscaInformacoesUsuarioLogado";

export const buscarLogado = () => async (dispatch, getState) => {
  try {
    const state = getState();
    if (state.usuario.isRequesting === false && !state.usuario.erro) {
      return Promise.resolve(state.usuario);
    }

    dispatch(buscarLogadoRequest());

    const infosUser = localStorageManager.getItem(INFOS_USUARIO);
    const { whatsApp, ...usuario } = infosUser;

    Axios.defaults.baseURL = env.REACT_APP_BASE_URL;
    Axios.interceptors.response.use(responseInterceptor, errorResponseInterceptor);
    Axios.interceptors.request.use(requestInterceptor, requestInterceptorError);

    dispatch(buscarLogadoSucesso(usuario));

    dispatch(setConfiguracao(objectKeysToCamelCase(whatsApp)));

    return usuario;
  } catch (error) {
    dispatch(buscarLogadoErro(error));
    throw new Error(error);
  }
};

export const invalidarCacheUsuarioLogado = () => CachedRequests.invalidateUrl(URL);

const updateLogoTipoCache = logoUrl => {
  const userCache = JSON.parse(localStorage.getItem(URL));

  if (userCache) {
    userCache.value.urlLogoEmpresa = logoUrl;
    localStorage.setItem(URL, JSON.stringify(userCache));
  }
};

const updateLogoTipoStore = logoUrl => createAction(USUARIO__UPDATE_LOGOTIPO, logoUrl);

export const updateLogotipo = logoUrl => dispatch => {
  const nextUrlComVersao = addVersaoLogotipo(logoUrl);
  updateLogoTipoCache(nextUrlComVersao);
  dispatch(updateLogoTipoStore(nextUrlComVersao));
};

export const IsUsuarioLogado = (dispatch, isLogado) => {
  dispatch(usuarioEstaLogado(isLogado));
};

export const selectLogotipo = state => state.usuario.urlLogoEmpresa;

export const addVersaoLogotipo = url => (typeof url === "string" && !!url ? `${url}?v=${v4()}` : "");
