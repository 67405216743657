(function(open) {
  const ALLOWED_URLS = ["cdn.inlinemanual.com/inm/data/data", "analytics.inlinemanual.com/__profile"];
  const STATE_OPENED = 1;
  const ALLOWED_HEADERS = ["accept", "accept-language", "content-language", "content-type"];
  const POST_METHOD = "POST";
  const GET_METHOD = "GET";
  const CONTENT_TYPE_HEADER = "Content-Type";
  const FORM_URL_ENCODED = "application/x-www-form-urlencoded";
  function isTargetURL(url) {
    return ALLOWED_URLS.some(allowedUrl => url.includes(allowedUrl));
  }
  function isAllowedHeader(header) {
    return ALLOWED_HEADERS.includes(header.toLowerCase());
  }
  function setAllowedHeaders(originalSetRequestHeader) {
    return function(header, value) {
      if (isAllowedHeader(header)) {
        originalSetRequestHeader.call(this, header, value);
      }
    };
  }
  function handleReadyStateChange(method, originalSetRequestHeader) {
    return function() {
      if (this.readyState === STATE_OPENED) {
        if (method === GET_METHOD) {
          this.setRequestHeader = setAllowedHeaders(originalSetRequestHeader);
        } else if (method === POST_METHOD) {
          this.setRequestHeader = setAllowedHeaders(originalSetRequestHeader);
          this.setRequestHeader(CONTENT_TYPE_HEADER, FORM_URL_ENCODED);
        }
      }
    };
  }
  XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
    if (isTargetURL(url)) {
      this.addEventListener("readystatechange", handleReadyStateChange(method, this.setRequestHeader), false);
    }
    open.call(this, method, url, async, user, password);
  };
})(XMLHttpRequest.prototype.open);
