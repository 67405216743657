import axios from "axios";

import createAction from "../../../_common/utils/redux/createAction";

export const TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_REQUEST = "TIPOSATIVIDADES/LISTAR_TIPOS_ATIVIDADE_REQUEST";
export const TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_RESPONSE = "TIPOSATIVIDADES/LISTAR_TIPOS_ATIVIDADE_RESPONSE";

export const TIPOS_ATIVIDADE__OPEN_MODAL_EXCLUIR = "TIPOSATIVIDADES/OPEN_MODAL_EXCLUIR";
export const TIPOS_ATIVIDADE__CLOSE_MODAL_EXCLUIR = "TIPOSATIVIDADES/CLOSE_MODAL_EXCLUIR";

export const TIPOS_ATIVIDADE__ABRIR_MODAL_TIPO_ATIVIDADE = "TIPOSATIVIDADES/ABRIR_MODAL_TIPO_ATIVIDADE";
export const TIPOS_ATIVIDADES__ABRIR_MODAL_EDITAR_TIPO_ATIVIDADE = "TIPOSATIVIDADES/ABRIR_MODAL_EDITAR_TIPO_ATIVIDADE";
export const TIPOS_ATIVIDADE__FECHAR_MODAL_TIPO_ATIVIDADE = "TIPOSATIVIDADES/FECHAR_MODAL_TIPO_ATIVIDADE";

export const handleAbrirModalTipoAtividade = () => createAction(TIPOS_ATIVIDADE__ABRIR_MODAL_TIPO_ATIVIDADE);
export const handleAbrirModalEditarTipoAtividade = tipoAtividade =>
  createAction(TIPOS_ATIVIDADES__ABRIR_MODAL_EDITAR_TIPO_ATIVIDADE, tipoAtividade);
export const handleFecharModalTipoAtividade = () => createAction(TIPOS_ATIVIDADE__FECHAR_MODAL_TIPO_ATIVIDADE);

export const listarTiposAtividadesRequest = () => createAction(TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_REQUEST);
export const listarTiposAtividadesResponse = tiposAtividade =>
  createAction(TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_RESPONSE, tiposAtividade);

export const handleOpenModalExcluir = tipoAtividadeId =>
  createAction(TIPOS_ATIVIDADE__OPEN_MODAL_EXCLUIR, tipoAtividadeId);
export const handleCloseModalExcluir = () => createAction(TIPOS_ATIVIDADE__CLOSE_MODAL_EXCLUIR);

export const handleListarTiposAtividade = () => async dispatch => {
  dispatch(listarTiposAtividadesRequest());

  const { data: tiposAtividades } = await axios.get("/api/pipeline/TipoAtividade/listar?somenteAtivos=false");

  dispatch(listarTiposAtividadesResponse(tiposAtividades));
};
