import {
  TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_REQUEST,
  TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_RESPONSE,
  TIPOS_ATIVIDADE__OPEN_MODAL_EXCLUIR,
  TIPOS_ATIVIDADE__CLOSE_MODAL_EXCLUIR,
  TIPOS_ATIVIDADE__ABRIR_MODAL_TIPO_ATIVIDADE,
  TIPOS_ATIVIDADE__FECHAR_MODAL_TIPO_ATIVIDADE,
  TIPOS_ATIVIDADES__ABRIR_MODAL_EDITAR_TIPO_ATIVIDADE,
} from "./tiposAtividadeActions";

export const returnInitialState = () => ({
  listagem: {
    tiposAtividade: [],
    isLoadingTiposAtividades: false,
  },
  modalExcluir: {
    open: false,
    tipoAtividadeId: null,
  },
  modalTipoAtividade: {
    open: false,
    tipoAtividadeEditando: {},
  },
});

const tiposAtividade = (state = returnInitialState(), action) => {
  switch (action.type) {
    case TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_REQUEST:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          isLoadingTiposAtividades: true,
        },
      };
    case TIPOS_ATIVIDADE__LISTAR_TIPOS_ATIVIDADE_RESPONSE:
      return {
        ...state,
        listagem: {
          ...state.listagem,
          tiposAtividade: action.payload,
          isLoadingTiposAtividades: false,
        },
      };
    case TIPOS_ATIVIDADE__OPEN_MODAL_EXCLUIR:
      return {
        ...state,
        modalExcluir: { open: true, tipoAtividadeId: action.payload },
      };
    case TIPOS_ATIVIDADE__CLOSE_MODAL_EXCLUIR:
      return {
        ...state,
        modalExcluir: { open: false, tipoAtividadeId: null },
      };
    case TIPOS_ATIVIDADE__ABRIR_MODAL_TIPO_ATIVIDADE:
      return {
        ...state,
        modalTipoAtividade: {
          ...state.modalTipoAtividade,
          open: true,
        },
      };
    case TIPOS_ATIVIDADE__FECHAR_MODAL_TIPO_ATIVIDADE:
      return {
        ...state,
        modalTipoAtividade: {
          open: false,
          tipoAtividadeEditando: {},
        },
      };
    case TIPOS_ATIVIDADES__ABRIR_MODAL_EDITAR_TIPO_ATIVIDADE:
      return {
        ...state,
        modalTipoAtividade: {
          ...state.modalTipoAtividade,
          open: true,
          tipoAtividadeEditando: action.payload,
        },
      };
    default:
      return state;
  }
};

export default tiposAtividade;
