import { LOCALE__SET_REQUEST, LOCALE__SET_RESPONSE } from "./localeActions";

const returnInitialState = () => ({
  locale: null,
  messages: null,
  isRequesting: null,
});

const locale = (state = returnInitialState(), action) => {
  switch (action.type) {
    case LOCALE__SET_REQUEST:
      return {
        ...state,
        language: action.payload.language,
        isRequesting: true,
      };
    case LOCALE__SET_RESPONSE:
      return {
        ...state,
        messages: action.payload.messages,
        isRequesting: false,
      };
    default:
      return state;
  }
};

export default locale;
