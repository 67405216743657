/* eslint-disable no-underscore-dangle */
/* eslint-disable vars-on-top */

import env from "../environment";

(function() {
  const baseUrl = env.REACT_APP_BASE_URL;
  const originalSend = XMLHttpRequest.prototype.send;

  XMLHttpRequest.prototype.send = function(body) {
    if (this._url.includes("eligibility.wootric.com/eligible.json")) {
      const newUrl = `${baseUrl}/Api/Pipeline/cors/wootric/json?${encodeURIComponent(this._url)}`;

      this.open(this._method, newUrl, this._async);
    }

    originalSend.call(this, body);
  };

  const originalOpen = XMLHttpRequest.prototype.open;
  XMLHttpRequest.prototype.open = function(method, url, async, user, password) {
    this._method = method;
    this._url = url;
    this._async = async;

    originalOpen.call(this, method, url, async, user, password);
  };
})();
