const defaultOptions = {
  propagate: true,
};

class ExactBroadcast {
  constructor(broadcastId) {
    this.events = {};
    this.eventId = null;
    this.broadcast = new BroadcastChannel(broadcastId);

    this.broadcast.onmessage = ({ data }) => {
      const { type = null, payload = null, eventId } = data;
      if (eventId !== this.eventId && typeof type === "string" && typeof this.events[type] === "object") {
        this.events[type].forEach(listener => listener.apply(this, [payload]));
      }
    };
  }

  dispatch(event, payload, { propagate } = defaultOptions) {
    if (typeof this.events[event] === "object") {
      this.events[event].forEach(listener => listener.apply(this, [payload]));
    }

    if (propagate) {
      this.eventId = Math.random()
        .toString(36)
        .substr(2, 9);

      this.broadcast.postMessage({
        eventId: this.eventId,
        type: event,
        payload,
      });
    }
  }

  on(event, listener) {
    if (typeof this.events[event] !== "object") {
      this.events[event] = [];
    }
    this.events[event].push(listener);
    return () => this.off(event, listener);
  }

  off(event, listener) {
    if (typeof this.events[event] === "object") {
      const idx = this.events[event].indexOf(listener);
      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
  }

  once(event, listener) {
    const remove = this.on(event, (...rest) => {
      remove();
      listener.apply(this, rest);
    });
  }
}

export default new ExactBroadcast("@ExactSpotterBroadcast");

export const eEvents = {
  FECHAR_MODAL_RESULTADO_LIGACAO: "FECHAR_MODAL_RESULTADO_LIGACAO",
  ABRIR_MODAL_RESULTADO_LIGACAO: "ABRIR_MODAL_RESULTADO_LIGACAO",
};

/**
 * @class
 * @description
 * Classe utilizada para comunicar entre abas do navegador, ou mesmo entre componentes
 * internos da aplicação, evitando o uso do objeto window do navagador (new CustonEvent).
 * Em um objeto são armazenados os EventListener. É possível disparar para todas
 * as abas abertas do navegador, ou somente para a aba que disparou o evento sendo
 * controlado pelo parametro "propagate".
 *
 *  * @method dispatch
 * Dispara um evento para os Listeners.
 * @param event :string
 * Identificador do evento
 * @param payload :any
 * Conteudo a ser enviado junto ao evento
 * @param config :object { propagate :boolean }
 * Configurações do comportamento do evento.
 *
 *
 *  * @method on
 * Adicona um evento. Pode armazenar no mesmo eventos diversos callbacks, como se trata de um singleton
 * é essencial remover os eventos no unmont do componente.
 * @param event :string
 * Identificador do evento
 * @param callback :function
 * Função de callback a ser executada ao disparar o evento.
 * @returns funcao de callback, que quando executada remove o evento do Listener.
 *
 *
 *  * @method off
 * Remove um evento do Listener.
 * @param event :string
 * Identificador do evento
 * @param callback :function
 * Função de callback registrada no Listener.
 *
 *
 *  * @method once
 * Adicona um evento porém esse evento ao ser disparado pela primeira vez é removido automaticamente do Listener.
 * @param event :string
 * Identificador do evento
 * @param callback :function
 * Função de callback a ser executada ao disparar o evento.
 */
