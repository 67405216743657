import { createSelector } from "reselect";
import moment from "moment-timezone";
import { v4 } from "uuid";
import { defineMessages } from "react-intl";

export const MENSAGENS = defineMessages({
  hoje: { defaultMessage: "HOJE" },
  ontem: { defaultMessage: "ONTEM" },
  observacaoAdicionada: { defaultMessage: "Observação adicionada: {param}" },
  chatOcultado: { defaultMessage: "Chat ocultado" },
});

export function addLabels(listMessages) {
  function MessageLabel(label, timestamp, type = "LABEL", param = "") {
    return {
      id: v4(),
      isMessageLabel: true,
      type,
      timestamp,
      label,
      param,
    };
  }

  const groupedByDate = {};
  const dateNow = new Date();

  const addGroup = (pastDays, label, item, timestamp) => {
    if (!groupedByDate[pastDays]) {
      groupedByDate[pastDays] = { pastDays, label, itens: [], timestamp };
    }
    groupedByDate[pastDays].itens.push({
      ...item,
      timestamp,
      formattedDate: moment(item.dateTimezone, "DD/MM/YYYY HH:mm:ss").format("HH:mm"),
    });
  };

  listMessages.forEach(item => {
    const messageDate = moment(item.dateTimezone, "DD/MM/YYYY HH:mm:ss");
    const pastDays = moment(dateNow)
      .startOf("day")
      .diff(messageDate.clone().startOf("day"), "days");

    if (pastDays === 0) {
      addGroup(pastDays, MENSAGENS.hoje, item, messageDate.valueOf());
    } else if (pastDays === 1) {
      addGroup(pastDays, MENSAGENS.ontem, item, messageDate.valueOf());
    } else {
      addGroup(pastDays, messageDate.format("DD/MM/YYYY"), item, messageDate.valueOf());
    }
  });

  const messages = Object.values(groupedByDate).reduce(
    (acc, curr) => [...acc, ...curr.itens, new MessageLabel(curr.label, curr.timestamp - 1, "DATE")],
    []
  );

  const lastMessagePerCycle = {};

  messages.forEach(msg => {
    if (msg.chatEncerrado || msg.observacoes) {
      if (msg.id > (lastMessagePerCycle[msg.ciclo] ?? 0)) {
        lastMessagePerCycle[msg.ciclo] = msg.id;
      }
    }
  });

  Object.values(lastMessagePerCycle).forEach(id => {
    const message = messages.find(msg => msg.id === id);
    if (message.chatEncerrado) messages.push(new MessageLabel(MENSAGENS.chatOcultado, message.timestamp + 1));
    if (message.observacoes)
      messages.push(
        new MessageLabel(MENSAGENS.observacaoAdicionada, message.timestamp + 2, "LABEL", message.observacoes)
      );
  });

  return messages.sort((a, b) => a.timestamp - b.timestamp);
}

const contatos = state => state.whatsApp.contatos;
const mensagens = state => state.whatsApp.mensagens;
const idChatAberto = state => state.whatsApp.idChatAberto;
const forcarAtualizacao = state => state.whatsApp.forcarAtualizacao;

export const getUnreadChatMessages = createSelector([contatos, mensagens], contacts =>
  Object.values(contacts).reduce((acc, cur) => acc + cur.qtdNaoLidos, 0)
);

export const getActiveContactChat = createSelector([contatos, idChatAberto], (contacts, id) => contacts[id] || {});

export const getContactsList = createSelector([contatos, forcarAtualizacao], contacts => {
  const dateNow = new Date();

  return Object.values(contacts)
    .map(contact => {
      const date = moment(contact.dateTimezoneUltimaMsgRecebida, "DD/MM/YYYY HH:mm:ss");
      const horasUltimaMensagem = moment(dateNow).diff(date, "hours");
      const minutosPassados = 60 * 24 - moment(dateNow).diff(date, "minutes");

      contact.minutosRestanteChatHabilitado = minutosPassados > 0 && minutosPassados < 60 ? minutosPassados : 0;
      contact.envioMsgBloqueado = !contact.dateTimezoneUltimaMsgRecebida || horasUltimaMensagem >= 24;
      return contact;
    })
    .sort(
      (a, b) =>
        moment(b.dateTimezone, "DD/MM/YYYY HH:mm:ss").valueOf() -
        moment(a.dateTimezone, "DD/MM/YYYY HH:mm:ss").valueOf()
    );
});

export const getMessages = createSelector(mensagens, messages => addLabels(messages));
