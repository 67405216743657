import * as signalR from "@microsoft/signalr";

const CANAL_BROADCAST_SOKECT = "SOCKET_BROADCAST";
const ACTION_LOGOUT_BROADCAST = "LOGOUT_SOCKET_BROADCAST";
class SocketSignalR {
  constructor() {
    this.events = {};
    this.attemps = 0;
    this.connection = null;
    this.state = {};
    this.intervalId = null;

    this.broadcastChannelSocket = new BroadcastChannel(CANAL_BROADCAST_SOKECT);

    this.broadcastChannelSocket.onmessage = async event => {
      if (event.data.action === ACTION_LOGOUT_BROADCAST) {
        await this.disconnect();
      }
    };
  }

  configure({
    url = "",
    hub = "",
    reconnectTimeout = 1000,
    maxReconnecAttempts = 1,
    state = {},
    usaAzureSignalR = false,
    ...options
  }) {
    this.state = state;
    this.reconnectTimeout = reconnectTimeout;
    this.maxReconnecAttempts = maxReconnecAttempts;

    if (this.connection && this.connection.state === signalR.HubConnectionState.Connected) this.connection.stop();
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${url}${hub}`, { ...options })
      .configureLogging(signalR.LogLevel.Critical)
      .withAutomaticReconnect()
      .build();
    const UMA_HORA_EM_MILISEGUNDOS = 60 * 60000;
    this.connection.serverTimeoutInMilliseconds = 16 * UMA_HORA_EM_MILISEGUNDOS;

    this.connection.on("Dispatch", ({ action, payload, clients }) => {
      let data = {};
      try {
        data = payload ? JSON.parse(payload) : {};
      } catch (error) {
        // eslint-disable-next-line
        console.error("Error when converting payload signalr: ", error);
      } finally {
        const { identificadorUsuario } = this.state;
        if (Array.isArray(clients) && clients.includes(identificadorUsuario)) {
          this.dispatch(action, data);
        }
      }
    });

    this.connection.on("Auth", () => {
      this.connection.send("Register", this.state).then(() => {
        this.attemps = 0;
      });
    });

    if (usaAzureSignalR) {
      this.connection.start().then(() => {
        this.intervalId = setInterval(() => {
          this.connection.invoke("SendHeartbeat", this.state).catch(err => console.error(err.toString()));
          this.attemps = 0;
        }, 20000);
      });
    }

    return this;
  }

  async connect() {
    if (!this.state.identificadorUsuario) {
      throw new Error("It is necessary to provide a user identifier");
    }
    if (this.attemps > this.maxReconnecAttempts) {
      throw new Error("Maximum number of connection attempts exceeded");
    }
    try {
      await this.connection.start();

      this.connection.onclose(async () => {
        let attempt = 0;

        const reconnect = async () => {
          if (attempt >= this.maxReconnecAttempts) {
            throw new Error("Maximum number of connection attempts exceeded");
          }

          try {
            await this.connection.start();
          } catch (err) {
            attempt++;

            const waitTime = Math.min(this.reconnectTimeout * 2 ** attempt, 30000);
            setTimeout(reconnect, waitTime);
          }
        };

        setTimeout(reconnect, this.reconnectTimeout);
      });
    } catch (error) {
      this.attemps += 1;

      //  Retry initial start failures
      setTimeout(() => {
        this.connect();
      }, this.reconnectTimeout);
    }
  }

  emit(action = "SendMessage", data) {
    if (this.connection.state === signalR.HubConnectionState.Connected) {
      this.connection.send(action, data);
    } else {
      throw new Error("The websocket connection has not been established");
    }
  }

  dispatch(event, ...rest) {
    if (typeof this.events[event] === "object") {
      this.events[event].forEach(listener => listener.apply(this, rest));
    }

    if (event === eActions.TESTE_NOTIFICACAO_CONSOLE) {
      // eslint-disable-next-line no-console
      console.log("SignalR conectado");
    }
  }

  on(event, listener) {
    if (typeof this.events[event] !== "object") {
      this.events[event] = [];
    }
    this.events[event].push(listener);
    return () => this.off(event, listener);
  }

  off(event, listener) {
    if (typeof this.events[event] === "object") {
      const idx = this.events[event].indexOf(listener);
      if (idx > -1) {
        this.events[event].splice(idx, 1);
      }
    }
  }

  once(event, listener) {
    const remove = this.on(event, (...rest) => {
      remove();
      listener.apply(this, rest);
    });
  }
  async disconnect() {
    await this.connection?.stop();
    this.stopIntervalHeartbeat();
    this.connection = null;
  }
  stopIntervalHeartbeat() {
    if (this.intervalId != null) {
      clearInterval(this.intervalId);
    }
  }
  notificarOutrasAbasDisconexaoSocket() {
    this.broadcastChannelSocket.postMessage({ action: ACTION_LOGOUT_BROADCAST });
  }
}

const socket = new SocketSignalR();

export default socket;

export const eActions = {
  WHATSAPP_RELOAD_CONTACTS: "WHATSAPP_RELOAD_CONTACTS",
  WHATSAPP_UPDATE_MESSAGE_READ: "WHATSAPP_UPDATE_MESSAGE_READ",
  WHATSAPP_RECEIVE_MESSAGE: "WHATSAPP_RECEIVE_MESSAGE",
  WHATSAPP_UPDATE_MESSAGE: "WHATSAPP_UPDATE_MESSAGE",
  WHATSAPP_ALTERAR_STATUS_CHAT: "WHATSAPP_ALTERAR_STATUS_CHAT",
  LEMBRETE_ATIVIDADE_V2: "LEMBRETE_ATIVIDADE_V2",
  SUGERIR_CONCLUSAO_ATIVIDADE: "SUGERIR_CONCLUSAO_ATIVIDADE",
  NOTIFICACAO_LEITURA_CENTRAL_EMAILS: "NOTIFICACAO_LEITURA_CENTRAL_EMAILS",
  WEBPHONE_COLETA_RESULTADO_LIGACAO: "WEBPHONE_COLETA_RESULTADO_LIGACAO",
  TESTE_NOTIFICACAO_CONSOLE: "TESTE_NOTIFICACAO_CONSOLE",
  ENCONTRAR_EMAILS_BUSCADOR_CONTATOS: "ENCONTRAR_EMAILS_BUSCADOR_CONTATOS",
};
