import { types } from "./loginActions";

const initialState = {
  errorMessage: "",
};
const login = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: action.payload,
      };
    case types.CLEAR_ERROR_MESSAGE:
      return {
        ...state,
        errorMessage: "",
      };
    default:
      return state;
  }
};

export default login;
