import createAction from "../../../_common/utils/redux/createAction";

import {
  WHATSAPP__SET_STATUS,
  WHATSAPP__SET_CONFIGURACAO,
  WHATSAPP__SET_LOADING,
  WHATSAPP__ADD_MENSAGEM,
  WHATSAPP__SET_CONTATOS_LISTA,
  WHATSAPP__SET_MENSAGEM,
  WHATSAPP__SET_MENSAGENS,
  WHATSAPP__SET_ID_CHAT_ABERTO,
  WHATSAPP__SET_MENSAGEM_LIDA,
  WHATSAPP__SET_SHARED_PARAMS,
  WHATSAPP__OPEN_MODAL_HISTORY,
  WHATSAPP__CLOSE_MODAL_HISTORY,
  WHATSAPP__MODAL_HISTORY_ADD_MESSAGE,
  WHATSAPP__MODAL_HISTORY_SET_MESSAGE,
  WHATSAPP__OPEN_MODAL_TRANSFERCHAT,
  WHATSAPP__CLOSE_MODAL_TRANSFERCHAT,
  WHATSAPP__SET_FORCE_UPDATE_STATE,
  WHATSAPP__OPEN_MODAL_HSM_SEND,
  WHATSAPP__CLOSE_MODAL_HSM_SEND,
} from "./types";

export const setStatus = status => createAction(WHATSAPP__SET_STATUS, status);
export const setConfiguracao = configuracao => createAction(WHATSAPP__SET_CONFIGURACAO, configuracao);
export const setLoading = value => createAction(WHATSAPP__SET_LOADING, value);
export const setContatosLista = contacts => createAction(WHATSAPP__SET_CONTATOS_LISTA, contacts);
export const setMessages = messages => createAction(WHATSAPP__SET_MENSAGENS, messages);
export const setIdActiveChat = chatId => createAction(WHATSAPP__SET_ID_CHAT_ABERTO, chatId);
export const setMessagesAsRead = chatId => createAction(WHATSAPP__SET_MENSAGEM_LIDA, chatId);
export const addMessage = (idChat, message) => createAction(WHATSAPP__ADD_MENSAGEM, { idChat, message });
export const setMessage = (idChat, message) => createAction(WHATSAPP__SET_MENSAGEM, { idChat, message });
export const setSharedParams = params => createAction(WHATSAPP__SET_SHARED_PARAMS, params);
export const openModalHistory = (title, messages, contato = {}, permiteEnvioMsg = false, somenteLeitura = true) =>
  createAction(WHATSAPP__OPEN_MODAL_HISTORY, { title, messages, contato, permiteEnvioMsg, somenteLeitura });
export const closeModalHistory = () => createAction(WHATSAPP__CLOSE_MODAL_HISTORY);
export const modalHistoryAddMessage = (message, permiteEnvioMsg) =>
  createAction(WHATSAPP__MODAL_HISTORY_ADD_MESSAGE, { message, permiteEnvioMsg });
export const modalHistorySetMessage = message => createAction(WHATSAPP__MODAL_HISTORY_SET_MESSAGE, { message });
export const openModalTransferChat = chat => createAction(WHATSAPP__OPEN_MODAL_TRANSFERCHAT, { chat });
export const closeModalTransferChat = () => createAction(WHATSAPP__CLOSE_MODAL_TRANSFERCHAT);
export const forceUpdateState = () => createAction(WHATSAPP__SET_FORCE_UPDATE_STATE);
export const openModalSendHSM = (templates, { destinatarios, destinatario }) =>
  createAction(WHATSAPP__OPEN_MODAL_HSM_SEND, { templates, destinatarios, destinatario });
export const closeModalSendHSM = () => createAction(WHATSAPP__CLOSE_MODAL_HSM_SEND);
