export const types = {
  SET_ERROR_MESSAGE: "SET_ERROR_MESSAGES",
  CLEAR_ERROR_MESSAGE: "CLEAR_ERROR_MESSAGE",
};
export const setErrorMessage = message => ({
  type: types.SET_ERROR_MESSAGE,
  payload: message,
});

export const clearErrorMessage = () => ({
  type: types.CLEAR_ERROR_MESSAGE,
});
