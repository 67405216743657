export const selectUsuario = state => state.usuario;

export const usuarioPossuiTelefoniaIntegrada = state => selectUsuario(state)?.telefoniaIntegrada;

export const usuarioIsPV = state => selectUsuario(state).isPreVendedor;
export const usuarioIsGerente = state => selectUsuario(state).isGerente;

export const selectUsuarioEstaLogado = state => selectUsuario(state).isUsuarioLogado;

export const selectTipoPlano = state => selectUsuario(state).tipoPlano;
